@import "~bootstrap/scss/functions";
.home-page {
  user-select: none;
  
  
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h1 {
    color: #C8CCCE;
    font-size: 56px;
    line-height: 56px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #00a19b;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #00a19b;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #00a19b;
    margin-top: 20px;
    font-weight: 400;
    font-size: 18px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
    display: inline;

    .typewriter-cursor {
      font-size: 32px;
      display: inline-block;
      vertical-align: -5px;
    }
  }

  .flat-button {
    color: #00a19b;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #00a19b;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #00a19b;
      color: #333;
    }
  }
  .profile-container {
    display: flex;
    justify-content: center; /* Center the image container */
    align-items: flex-start;
    height: 100%; /* Adjust the height as needed */
    margin-left: -100px;

  }
  
  .profile-pic {
    width: 200px; /* Set the width as desired */
    height: 200px; /* Set the height to be equal to the width for a perfect circle */
    transition: all .3s;
    border-radius: 15px;
    box-shadow: 2rem 2rem hsl(178, 100%, 32%);
    border: 3px solid #C8CCCE; /* Adjust the color and size of the border as desired */
    object-fit: cover; /* This will cover the area without stretching the image */
  }

  .profile-pic:hover {
    transform: translate(-0.5rem, -0.5rem);
    box-shadow: 3rem 3rem #00a19b;
  }
  
}
