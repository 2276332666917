.project-page {
    padding-left: 200px;
    padding-right: 100px;
    width: calc(100% - 300px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 0;
        margin-top: 100px;
    }

    .images-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
    }

    .image-box {
        position: relative;
        flex: 1 1 30%;
        height: 400px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(33.33% - 10px);

        .project-image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.5) 0,
                rgba(0, 0, 0, 1)
            );
            bottom: -70px;
        }

        .title {
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 10px;
            margin-bottom: 0;
            margin-top: 0;
            color: #C8CCCE;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            padding: 10px 20px;
            font-size: 14px;
            margin-top: 0;
            margin-bottom: 5px;
            color: #C8CCCE;
            font-weight: 700;
        }

        .btn {
            margin-top: 10px;
            margin-left: 20px;
            margin-bottom: 20px;
            text-decoration: none;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            letter-spacing: 3px;
            border: 2px solid #00A19B;
            border-radius: 4px;
            font-size: 12px;
            color: #00A19B;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;
        }

        .btn:hover {
            transform: translateY(-3px);
            background: #00A19B;
            color: #071C21;
        }

        &:after {
            content: '';
            background: linear-gradient(180deg, #00A19B, #000000);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }

        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }
}

@media screen and (max-width: 1200px) {
    .container.project-page {
        width: 100%;
        padding: 30px;
        padding-top: 120px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 0px;
            margin-top: 0px;
        }

        .image-box {
            height: 200px;
            flex: 1 1 100%;
            max-width: calc(100% - 10px);
        }
    }
}