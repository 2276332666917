.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.top-tags {
  user-select: none;
  bottom: auto;
  top: 35px;
}

.tags {
  user-select: none;
  color: #00a19b;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tag-html {
  user-select: none;
  margin-left: -20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.project-page,
.skills-page,
.journey-page {

  user-select: none;

  h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #00a19b;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &::before {
          content: '<h1>';
          font-family: 'La Belle Aurore';
          font-size: 18px;
          position: absolute;
          margin-top: -10px;
          left: -10px;
          opacity: 0.6;
          line-height: 18px;
      }

      &::after {
          content: '</h1>';
          font-family: 'La Belle Aurore';
          font-size: 18px;
          line-height: 18px;
          position: absolute;
          left: -30px;
          bottom: -20px;
          margin-left: 20px;
          opacity: 0.6;
      }
  }

  p {
      font-size: 16px;
      color: #C8CCCE;
      font-family: 'Tilt Neon';
      font-weight: 300;
      text-align: justify;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
          animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
          animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
          animation-delay: 1.3s;
      }

      &:nth-of-type(4) {
          animation-delay: 1.4s;
      }
  }

  li {
      animation: pulse 1s;

      &:nth-of-type(1) {
          animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
          animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
          animation-delay: 1.3s;
      }

      &:nth-of-type(4) {
          animation-delay: 1.4s;
      }

      &:nth-of-type(5) {
          animation-delay: 1.5s;
      }

      &:nth-of-type(6) {
          animation-delay: 1.6s;
      }

      &:nth-of-type(7) {
          animation-delay: 1.7s;
      }
  }

  .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
  }

  .text-animate-hover {
      &:hover {
          color: #C8CCCE;
      }
  }
}


@media screen and (max-width: 1440px) {
  .container {

      &.about-page,
      &.project-page {
          .text-zone {
              left: 15%;
          }
      }
  }

}

@media screen and (max-width: 1200px) {
  .page {
      position: initial;
  }

  .container {
      position: initial;
      height: auto;
      min-height: auto;

      &.about-page,
      &.contact-page,
      &.project-page,
      &.skills-page {
          .text-zone {
              position: initial;
              transform: none;
              width: 100%;
              display: block;
              padding: 30px;
              padding-top: 120px;
              box-sizing: border-box;
          }

          h1::after {
              //display: none;
              left: 0px;
          }

          h1::before {
              //display: none;
              left: 0px;
          }
      }

      &.about-page {
          ul {
              padding-left: 0;
          }
      }
  }
}