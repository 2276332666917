.journey-page {
    padding-left: 200px;
    padding-right: 100px;
    width: calc(100% - 300px);
    position: initial;
    height: 100%;
    overflow: auto;

    h1.page-title {
        margin-left: 0;
        margin-top: 100px;
    }


    .timeline {
        display: flex;
        position: relative;
        width: 80%;
        margin: auto;
        z-index: 1;
        gap: 12px;

        ul {
            li {
                opacity: 0;
                transform: translateY(20px);
                transition: opacity 0.5s ease, transform 0.5s ease;

                &.is-visible {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}


@media screen and (max-width: 1440px) {
    .container.journey-page {

        .timeline {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .container.journey-page {
        width: 100%;
        padding: 30px;
        padding-top: 120px;
        box-sizing: border-box;

        h1.page-title {
            margin-left: 0px;
            margin-top: 0px;
        }

        .timeline {
            width: 100%;
        }
    }
}