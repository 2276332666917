.nav-bar {
    background: #222222;
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 8px 0;

        img {
            display: block;
            margin: 8px auto;
            width: 35px;
            height: auto;

            &.sub-logo {
                width: 35px;
            }
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        margin-top: -120px;
        width: 100%;

        a {
            font-family: 'Tilt Neon';
            font-size: 25px;
            color: #C8CCCE;
            display: block;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #00a19b;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 14px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transform: all 0.3s ease-out;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.skills-link {
            &:after {
                content: 'SKILLS';
            }
        }

        a.contact-link {
            &:after {
                content: 'CONTACT';
            }
        }

        a.project-link {
            &:after {
                content: 'PROJECTS';
            }
        }

        a.journey-link {
            &:after {
                content: 'JOURNEY';
            }
        }

        a.active {
            svg {
                color: #14b5af;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a{
                padding: 7px 0;
                display: block;
                font-size: 25px;
                line-height: 16px;

                &:hover svg {
                    color: #00a19b;
                }
            }
        }
    }
}

.hamburger-icon {
    display: none;
}


@media screen and (max-width: 1200px) {
    .nav-bar {
        background: transparent;
        position: initial;
        height: auto;
        min-height: auto;

        ul, nav {
            display: none;
        }

        nav {
            width: 100%;
            height: 100%;
            background: #222222;
            top: 0;
            left: 0;
            position: fixed;
            z-index: 2;
            margin: 0;
            padding-top: 80px;

            a {
                display: flex;
                justify-content: right;
                align-items: center;
                flex-direction: row-reverse; // Align SVG icons to the right
                padding: 10px;
                padding-right: 20px;

                &.active {
                    color: #00a19b;
                }
            }
    
            a:after {
                opacity: 1;
                position: initial;
                width: auto;
                margin-left: 10px;
                font-size: 15px;
            }

            a svg {
                opacity: 1 !important;
                margin-left: 15px;

                &:hover {
                    color: #00a19b;
                }
            }
        }
        .mobile-show {
            display: block;
        }

        .hamburger-icon,
        .close-icon {
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            color: #00a19b;
        }
    }
}